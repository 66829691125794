<template>
  <div class="flex justify-center">
    <div class="w-full md:w-2/3">
      <div class="grid md:grid-cols-3 md:gap-5">
        <div
          class="max-w-xs rounded overflow-hidden shadow-lg my-2"
          :key="index"
          v-for="(item, index) in content"
        >
          <div class="w-full position-relative">
            <b-avatar
              variant="danger"
              size="4rem"
              :text="`${++index}`"
              class="position-absolute top-10 left-10"
            ></b-avatar>
            <img
              class="w-full"
              :src="require(`@/assets/img/${item.number}.png`)"
              alt="Sunset in the mountains"
            />
          </div>

          <div class="px-6 py-4">
            <p class="text-grey-darker text-center text-base">
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "howitworksitem",
  props: ["content"],
};
</script>

<style>
</style>