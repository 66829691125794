<template>
  <div>
    <howitworks
      :title="title"
      :img="img"
      :message="message"
      :imageurl="imageurl"
      :header="header"
      :content="content"
    ></howitworks>
  </div>
</template>

<script>
import howitworks from "@/components/extra/howitworks";

export default {
  name: "How",
  data() {
    return {
      header: "VAPT",
      title: "ABOUT VAPT",
      message: `Premium Pentest is typically designed to check the box on Teklabspace gives you so much more. We start by working with you to align the project’s scope with your needs. We’ll then gather details on your attack surface and ensure hackers are fully aware of your unique targets and goals. As hackers use real-world tactics to assess your scope for vulnerabilities, they’ll submit reports through our platform, giving you instant visibility into findings and potential risks. At the conclusion of the pentest, you’ll receive a detailed report of findings, recommendations, and remediation results to share with your auditors and use to improve your overall security..   `,
      img: "vapt-header.png",
      imageurl: "vapt.png",
      content: [
        {
          number: "vapt1",
          content: "Recruit expert-level Hackers",
        },
        {
          number: "vapt2",
          content: "Kickoff call with customer",
        },
        {
          number: "vapt3",
          content:
            "Ensure scope and approach are meeting customer needs OUTCOMES",
        },
        {
          number: "vapt4",
          content: "Project Scope Agreement",
        },
        {
          number: "vapt5",
          content: "Testing and Retesting Rules of Engagement",
        },
        {
          number: "vapt6",
          content:
            "Exploit Walkthrough: Hackers showing customers how attackers would exploit systems & applications",
        },
        {
          number: "vapt7",
          content: "Retesting of Findings",
        },
        {
          number: "vapt8",
          content:
            'Provide report with "Hacker Perspective", including: Executive Summary, Finding Recommendations, Individual Findings with exploit severity & remediation guidance',
        },
        {
          number: "vapt9",
          content: "Project closing activities",
        },
        {
          number: "vapt10",
          content: "Customer Outbrief",
        },
      ],
    };
  },
  components: {
    howitworks,
  },
};
</script>

<style>
</style>